$c-black: #262626;
$c-grey: #D8D8D7;
$c-light-grey: #ECECEC;
$c-red: #65BC2F;
$c-white: #FFF;

$c-green: #4D8F46;
$c-blue: #3B5998;
$c-orange: #F99813;
$c-yellow: #ED5;

$c-fb: $c-blue;
$c-yt: #F00;
